<template>
  <div class='container'>
    <div class="content">
      <div class="box">
        <p>学校名称</p>
        <input type="text" v-model="form.orgName">
      </div>
      <div class="box select"  @click="pupupShow('教育级别')">
        <p>教育级别</p>
        <input type="text" v-model="levelName" readonly>
        <!-- <div class="select">{{ levelName }}</div> -->
        <van-icon name="arrow-down" class="arrow"/>
      </div>
      <div class="box select" @click="pupupShow('地区')" readonly>
        <p>地区</p>
        <input type="text" v-model="areaName">
        <!-- <div class="select">{{ areaName }}</div> -->
        <van-icon name="arrow-down" class="arrow"/>
      </div>
      <!-- <div class="box select">
        <p>详细地址</p>
        <input type="text" v-model="form.addr">
        <van-icon name="arrow-down" class="arrow"/>
      </div> -->
      <div class="box" style="align-items: flex-start;">
        <p>详细地址</p>
        <input type="text" v-model="form.addr">
        <!-- <textarea type="text" v-model="form.addr" placeholder="请输入详细地址"/> -->
      </div>
      <div class="box">
        <p>手机号</p>
        <input type="text" v-model="user.phone">
      </div>
      <div class="box" style="position: relative;">
        <p>验证码</p>
        <input type="text" v-model="user.vcode" placeholder="请输入返回的验证码">
        <button class="yzm" @click="sendCode">{{btnText}}</button>
      </div>
      <div class="box">
        <p>联动队微信二维码</p>
        <div style="display: flex;align-items: center;width: 100%;">
          <p style="color: rgba(119, 119, 119, 1);margin-right: 4vw;margin-bottom: 0;" v-if="form.imgHelperQrcode">已上传</p>
          <van-uploader
           :action="uploadUrl" 
           :before-read="beforeRead"
           :after-read="afterRead">
            <button class="box-btn">{{form.imgHelperQrcode?'修改':'上传'}}</button>
          </van-uploader>
          <!-- <button class="box-btn">修改</button> -->
        </div>
      </div>
      <van-popup v-model="isShow" position="bottom" :style="{ height: '40%' }">
        <van-picker
          title="标题"
          show-toolbar
          value-key="title"
          :columns="levelList"
          @confirm="onLevelConfirm"
          @cancel="isShow=false"
          v-if="modelName==='教育级别'"
        />
        <van-area 
          title="选择所在地区" 
          :area-list="areaList"  
          @confirm="onAreaConfirm"
          v-if="modelName==='地区'"/>
      </van-popup>
    </div>
    <div class="bottom">
      <button @click="$router.push('/my')">返回</button>
      <button @click="update">修改</button>
    </div>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import {upload,getInfo,orgUpdate,phoneUpdate,getVcode} from '@/api/user.js'
export default {
  data(){
    return{
      form:{
        orgName: '',
        province: '',
        city: '',
        county: '',
        addr: '',
        level: '',
        imgHelperQrcode: ''
      },
      phone:'',
      user: {
          phone: '',
          vcode: ''
      },
      btnText: '发送验证码',
      time:0,
      areaList,
      imgUrl:'https://pmtadmin3.uelink.com.cn/upload',
      uploadUrl:'https://pmtadmin3.uelink.com.cn/orgAdmin/upload',
      levelList: ['小学','中学','中小学'],
      addressArr:[],
      isShow:false,
      modelName:'',
    }
  },
  created(){
 
  },
  mounted(){
    this.setArea()
    this.getUserInfo()
  },
  computed:{
    levelName(){
      console.log(this.levelList[this.form.level]);
      return this.levelList[this.form.level]
    },
    areaName(){
      console.log(this.addressArr);
      if(this.addressArr instanceof Array){
        this.addressArr=[this.form.province,this.form.city,this.form.county]
        return this.addressArr.join(' / ')
      }else{
        return this.addressArr.map(item=>item.name).join(' / ')
      }
    }
  },
  methods:{
    async getUserInfo(){
      const res = await getInfo()
      const {org}=res.data
      console.log(org);
      this.form={
        orgName: org.orgName,
        province: org.province,
        city: org.city,
        county: org.county,
        addr: org.addr,
        level: org.level,
        imgHelperQrcode: this.imgUrl+org.imgHelperQrcode
      }
      this.user.phone=org.phone
      this.phone=org.phone
    },
    pupupShow(modelName){
      this.modelName=modelName
      this.isShow=true
    },
    onLevelConfirm(level,index){
      this.form.level=index
      this.isShow=false
    },
    onAreaConfirm(arr){
      console.log(arr);
      this.addressArr=arr
      this.form.province=arr[0].name
      this.form.city=arr[1].name
      this.form.county=arr[2].name
      this.isShow=false
    },
    // 发送验证码
    sendCode () {
        if(this.time!==0){
          return
        }
        if (!this.user.phone) {
            this.$toast.fail('请先输入手机号')
            return
        }
        if (!(/^[1][2,3,4,5,6,7,8][0-9]{9}$/.test(this.user.phone))) {
            this.$toast.fail('请输入正确的手机号')
            return
        }
        const data = {
            phone: this.user.phone,
            type: 3
        }
        getVcode(data)
            .then(() => {
                this.$toast.success('获取成功')
                this.time = 60
                this.timer()
            })
            .catch(() => {
                this.$toast.fail('获取失败')
            })
    },
    async update () {
        const formData = JSON.parse(JSON.stringify(this.form))
        const user = JSON.parse(JSON.stringify(this.user))
        if (!formData.orgName.trim()) {
            this.$toast.fail('学校名称不能为空')
            return
        }
        if (formData.level === '' || formData.level === null) {
            this.$toast.fail('教育级别不能为空')
            return
        }
        if (!formData.province === '') {
            this.$toast.fail('省不能为空')
            return
        }
        if (!formData.city) {
            this.$toast.fail('市不能为空')
            return
        }
        if (!formData.county) {
            this.$toast.fail('区/县不能为空')
            return
        }
        if (!formData.addr) {
            this.$toast.fail('详细地址不能为空')
            return
        }
        // if (!formData.imgHelperQrcode) {
        //     this.$toast.fail('联动成员微信二维码不能为空')
        //     return
        // }
        if (!user.phone) {
            this.$toast.fail('请输入手机号码')
            return
        }
        if (!(/^[1][2,3,4,5,6,7,8][0-9]{9}$/.test(user.phone))) {
            this.$toast.fail('请输入正确的手机号')
            return
        }
        if (!user.vcode.trim()) {
            this.$toast.fail('请输入验证码')
            return
        }
        try {
          if (this.phone !== user.phone) {
           await phoneUpdate(user)
           this.$toast.success('修改手机成功')
          }
           await orgUpdate(formData)
              this.$toast.success('修改信息成功')
              this.$router.go(-1)
        } catch (error) {
          console.log(error)
        }
    },
    // 验证码倒计时
    timer () {
        if (this.time > 0) {
            this.time--
            this.btnText = this.time + '秒'
            setTimeout(this.timer, 1000)
        } else {
            this.time = 0
            this.btnText = '发送验证码'
        }
    },
    // 读取文件函数
    async afterRead(file){
      // console.log(formData);
      console.log(file);
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append('file', file.file);
      formData.append('type','imgHelperQrcode');

      const res = await upload(formData)
      this.form.imgHelperQrcode=`${this.imgUrl}${res.data.filePath}`
    },
    // 配置区域
    setArea () {
      const list = []
      const provinceList = areaList.province_list
      const cityList = areaList.city_list
      const countyList = areaList.county_list
      for (const key in provinceList) {
          list.push({
              value: key,
              label: provinceList[key],
              children: []
          })
      }
      const cityArr = []
      for (const key in cityList) {
          cityArr.push({
              value: key,
              label: cityList[key],
              children: []
          })
      }
      cityArr.forEach((city) => {
          const code = city.value.slice(0, 4)
          for (const key in countyList) {
              if (key.indexOf(code) > -1) {
                  city.children.push({
                      value: key,
                      label: countyList[key]
                  })
              }
          }
      })
      list.forEach((province) => {
          const code = province.value.slice(0, 2)
          cityArr.forEach((item) => {
              if (item.value.indexOf(code) > -1) {
                  province.children.push(item)
              }
          })
      })
      this.list = list
    },
    beforeRead(file){
      console.log(file);
      return true
    },
  }
}
</script>
<style lang='less' scoped>
.container{
  padding-bottom: 16.5333vw;
  min-height: 100%;
  background-color: rgba(248, 248, 248, 1);
  .content{
    background-color: #fff;
    .box{
      display: flex;
      flex-direction: column;
      padding:0 5.3333vw 4.8vw 5.3333vw;
      // line-height: 13.8667vw;
      align-items: flex-start;
      // justify-content: space-between;
      // height: 15.4667vw; 
      // font-size: 4.2667vw;
      &:nth-of-type(1){
        padding-top: 4.8vw;
      }
      p{
        font-weight: 550;
        margin-bottom: 3.4667vw;
      }
      input{
        padding: 0 6.1333vw;
        width: 100% ;
        height: 12.2667vw;
        border-radius: 13.3333vw;
        background: #F5F7F9;
      }
      textarea{
        height: 12.2667vw;
        flex: 1;
        text-align: right;
      }
      .yzm{
        position: absolute;
        width: 26.6667vw;
        top: 34%;
        right: 5.3333vw;
        height: 11.2vw;
        line-height: 11.2vw;
        font-size: 3.7333vw;
        text-align: center;
        border-radius: 5.3333vw;
        color: #fff;
        background-color: #5EDBC9;
      }
      .box-btn{
        /* // margin-left: 2.6667vw;
        padding: 0 4vw; */
        padding: 0 5.3333vw;
        height: 7.4667vw;
        line-height: 7.4667vw;
        color: #fff;
        font-size: 3.7333vw;
        text-align: center;
        background-color: #5EDBC9;
        border-radius: 5.3333vw;
        
      }
    }
    .box.select{
      position: relative;
      input{
        padding-right: 5.3333vw;
      }
      .select{
        flex: 1;
        text-align: right;
        padding-right: 5.3333vw;
      }
      .arrow{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5.3333vw;
      }
    }
  }
  .bottom{
    position: fixed;
    padding: 0 4vw;
    display: flex;
    align-items: center;
    width: 100%;
    bottom: 0;
    height: 16vw;
    font-size: 4.2667vw;
    color: #fff;
    background-color: #fff;
    button{
      flex: 1;
      height: 13.8667vw;
      color: #47D5B2;
      background: #EFF9F8;
      border-radius: 13.3333vw;
      &:nth-of-type(2){
        background: #10CA9B;
        color: #fff;
        margin-left: 4vw;
      }
    }
  }
}
</style>